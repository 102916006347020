@import "./variables/colors";
@import "./variables/defaults";

.dark {
  --actionItem-background-color: #{$Color-darkerBlue};
}

.light {
  --actionItem-background-color: #{$Color-lightBlue};
}

.actionItem {
  width: 100%;
  min-height: 150px;
  height: auto;
  // border: var(--task-border);
  // background: var(--task-background-color);
  padding: 20px;
  background: var(--actionItem-background-color);
  box-sizing: border-box;
  margin-bottom: 5px;
  position: relative;
  box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.2);
  transition: 0.5s box-shadow ease-in-out;
  display: flex;
  flex-direction: column;

  &--visible {
    box-shadow: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;

    &__buttons {
      display: inline-flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      .Button {
        flex-grow: 1;
      }
    }

    .contentBlock {
      width: 100%;
      margin-top: 0px;
      &__title {
        font-size: 1.25em;
      }
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .actionItem {
    padding: var(--vertical-padding) var(--horizontal-padding);

    &__content {
      .contentBlock {
        margin-bottom: var(--vertical-padding);

        &__title {
          font-size: 1.2em;
        }

        &__tagContainer {
          top: var(--vertical-padding);
          right: var(--horizontal-padding);

          &__tag {
            font-size: 0.8em;
          }
        }
      }

      &__buttons {
        .Button {
          font-size: 0.9em;
          padding: 0.7em 2em 0.9em;
        }
      }
    }
  }
}
