.Logo {
  &--light {
    .text {
      fill: #003148;
    }

    .logoTop {
      fill: #cd5a19;
    }

    .logoBottom {
      fill: #003148;
    }
  }

  &--dark {
    .text {
      fill: #ffffff;
    }

    .logoTop {
      fill: #ffffff;
    }

    .logoBottom {
      fill: #ffffff;
    }
  }

  #LogoSvg {
    height: 21px;
    width: auto;
  }
}
