@import "./variables/defaults";
@import "./variables/colors";

.light {
  --profile-category-border-color: #{$Color-lightGrey};
}

.dark {
  --profile-category-border-color: #{$Color-lightBlue};
}

.Profile {
  &__content {
    .contentBlock {
      &:not(:first-of-type),
      &:not(:last-of-type) {
        margin-top: 30px;
        border-top: 1px solid var(--profile-category-border-color);
        padding-top: 20px;
      }

      &:first-of-type {
        border-top: none;
        margin-top: 0;
      }
    }

    .Button {
      margin-bottom: 10px;
    }
  }
}

.flex__container {
  display: flex;

  &.--justify {
    justify-content: space-between;
    align-items: center;
  }

  &.--horizontal {
    flex-direction: row;
  }

  &.--vertical {
    flex-direction: column;
  }
}
