$Color-darkBlue: #003148;
$Color-darkerBlue: darken($color: $Color-darkBlue, $amount: 5%);
$Color-orange: #cd5a19;
$Color-hover-orange: #d77b47;
$Color-white: #ffffff;
$Color-black: #242424;
$Color-darkGrey: #3a3a38;
$Color-lightGrey: #c3c3c3;
$Color-hover-grey: #d7d7d7;
$Color-lightBlue: #f3f4f6;
$Color-grey: #707070;
$Color-grey-transparent: rgba(58, 58, 56, 0.4);
$Color-grey-border: #ececec;
$Color-btn-grey: #A9AAAA;
$Color-red: #d93737;

$Color-inset-border: rgba(0, 0, 0, 0.16);

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}
