@import "./variables/defaults";
@import "./variables/colors";

.light {
  --svgBg-color: #{$Color-lightBlue};
  --svg-icon: #{$Color-orange};
}

.dark {
  --svgBg-color: #{$Color-darkerBlue};
  --svg-icon: #{$Color-white};
}

.itemContainer {
  margin: 20px auto;
  font-weight: bold;
  position: relative;
  height: auto;

  &__tasks {
    height: auto;
    position: relative;
    z-index: 1;
  }

  &__header {
    display: inline-block;
    width: 100%;

    &__title {
      color: $Color-orange;
      margin-bottom: 5px;
      float: left;
    }

    &__arrow {
      float: right;
      width: 20px;
      height: auto;
      margin-top: 8px;
    }

    &__information {
      float: left;
      display: block;
      margin-left: 10px;
      margin-top: 4px;
      max-height: 34px;

      & svg {
        width: 21px;
        height: 21px;
      }

      & .closingButton {
        width: 53px;
        position: relative;
        height: 39px;
        bottom: 2px;
        left: -16px;
      }

      & .svgCircle {
        fill: $Color-orange;
      }

      & .svgWhite {
        fill: $Color-white;
      }

      & .svgCircleClosing {
        fill: var(--svgBg-color);
      }

      & .svgCross {
        fill: var(--svg-icon);
      }
    }

    &__overlay {
      float: left;
      width: 100%;
      pointer-events: none;
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {

// }

@media screen and (max-width: $breakpointMobileSmall) {
  .itemContainer {
    &__header {
      &__arrow {
        width: 12px;
      }
    }
  }
}
