@import "./variables/colors";
@import "./variables/defaults";

.light {
  --button-outline-border-color: #{$Color-lightGrey};
}

.dark {
  --button-outline-border-color: #{$Color-white};
}

.Button {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 12px 20px;
  border-radius: 999px;
  font-weight: 700;
  display: inline-block;
  border: 1px solid transparent;
  color: var(--text-color);
  font-size: 0.9em;

  &:disabled,
  &[disabled] {
    background: $Color-lightGrey !important;
    color: $Color-grey !important;
    pointer-events: none;
    border: 1px solid transparent !important;
  }

  &--orange {
    background: $Color-orange;
    color: $Color-white;
  }

  &--secondary {
    background: transparent;
    color: $Color-orange;
    border: 1px solid $Color-orange;
  }

  &--secondary--modal {
    background: var(--background-color);
    color: $Color-orange;
    border: 1px solid $Color-orange;
  }

  &--outline {
    background: transparent;
    border: 1px solid var(--button-outline-border-color);
  }

  &.fill-width {
    width: 100%;
  }

  &.color {
    &--red {
      color: $Color-red;
    }
  }

  &.modalButton:first-child {
    margin-right: 10px;
  }
}
