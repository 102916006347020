@import "./variables/colors";

.logo-subline {
  &--light {
    .text {
      fill: $Color-darkBlue;
    }

    .logoTop {
      fill: $Color-orange;
    }

    .logoBottom {
      fill: $Color-darkBlue;
    }
  }

  &--dark {
    .text {
      fill: $Color-white;
    }

    .logoTop {
      fill: $Color-white;
    }

    .logoBottom {
      fill: $Color-white;
    }
  }

  #logoSublineSvg {
    height: 80px;
    width: auto;
  }
}
