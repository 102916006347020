@import "./variables/defaults";
@import "./variables/colors";

.Setting {
  width: 100%;
  height: auto;
  margin: 5px 0 15px;
  font-family: $mainFontFam;

  &--disabled {
    pointer-events: none;
    filter: grayscale(1) opacity(0.4);
  }

  &--type {
    &--input {
      .Setting__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &--boolean {
      .Setting__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;

        .Setting__label {
          font-weight: 400;
        }
      }
    }

    &--select {
      .Setting__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;

        .Setting__label {
          font-weight: 400;
        }

        .Setting__select {
          &__element {
            appearance: none;
          }
        }
      }
    }

    &--link + &--link {
      border-top: none;
    }
    
    &--link {
      color: inherit;
      text-decoration: none;
      display: block;
      margin: 0;
      border-top: 1px solid var(--profile-category-border-color);
      border-bottom: 1px solid var(--profile-category-border-color);

      .Setting__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 400;
        align-items: center;
        min-height: 35px;
        

        .Setting__label {
          font-weight: 400;
        }

        .Setting__icon {
          color: var(--profile-category-border-color);
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: auto;
  }

  &__label {
    font-size: 0.9em;
    font-weight: 700;
    text-align: start;
  }

  &__input {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5px;

    &__element {
      max-width: 460px;
      width: 100%;
      padding: 0.75em 0.9em;
      border-radius: 10px;
      margin: 0;
      position: relative;
      display: block;
      box-sizing: border-box;
      border: 1px solid var(--inputField-border-color);
      box-shadow: inset 0 0 3px $Color-inset-border;
      background: var(--inputField-background-color) !important;
      color: var(--text-color);
      font-size: 0.9em;
      outline-width: 0;

      &--hasIcon {
        padding-right: calc(var(--vertical-padding) * 2);
      }
    }
  
    &__icon {
      position: absolute;
      top: 50%;
      right: calc(var(--horizontal-padding) / 2);
      transform: translateY(-50%);
      font-size: 12px;
      user-select: none;
      pointer-events: hidden;
    }
  }

  &__boolean {
    display: flex;
  }

  &__select {
    position: relative;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__element {
      background: none;
      border: none;
      outline: none;
      font-size: 0.9em;
      color: inherit;
      text-align: right;
    }

    &__icon {
      font-size: 13px;
      margin-left: 5px;
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .Setting {
    margin-bottom: 10px;

    &--type {
      &--boolean {
        .Setting__content {
          min-height: auto;
        }
      }
    }
  }
}
