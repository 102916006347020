@import "./variables/defaults";
@import "./variables/colors";

.dark {
  --background-color-btn: #{$Color-darkBlue};
  --background-color: #{$Color-darkBlue};
  --text-color: #{$Color-white};
  --toggle-background-color: rgba(255, 255, 255, 0.2);
  --toggle-background-color-checked: #{$Color-orange};
  --border-color: #{$Color-lightBlue};
  --loader-background-color: #{$Color-lightBlue};
  --loader-foreground-color: #{$Color-orange};
  --main-btn-background-hover-color: #{$Color-hover-orange};
  --secondary-btn-background-color: #{$Color-lightBlue};
  --secondary-btn-background-hover-color: #{$Color-hover-grey};
  --inputField-background-color: #{$Color-darkBlue};
  --inputField-border-color: #{$Color-lightGrey};
  --textLink-color: #{$Color-orange};
  --toast-background-color: #{$Color-darkerBlue};
  --toast-color: #{$Color-white};
  --toast-icon-color-primary: #{$Color-orange};

  --klaasje-border-color: #{$Color-orange};
  --klaasje-startup-background-color: #{$Color-white};
  --task-background-color: #{$Color-darkBlue};
  --task-border: 1px solid #{$Color-white};
  --task-btn-background-color: #{$Color-white};
  --task-btn-text-color: #{$Color-darkBlue};
  --task-modal-gradient: linear-gradient(
    180deg,
    #{rgba($Color-darkBlue, 0)} 0%,
    #{rgba($Color-darkBlue, 0.908000700280112)} 25%,
    #{rgba($Color-darkBlue, 1)} 100%
  );
  --tag-modal-gradient: linear-gradient(
    180deg,
    #{rgba($Color-darkBlue, 1)} 0%,
    #{rgba($Color-darkBlue, 0.908000700280112)} 75%,
    #{rgba($Color-darkBlue, 0)} 100%
  );
  --meditation-toggle-background: #{$Color-darkerBlue};
  --meditation-toggle-inactive-textcolor: #{$Color-orange};

  --startup-waves-color: #{$Color-white};
  --splash-gradient-background: linear-gradient(
    167deg,
    #{rgba($Color-darkBlue, 1)} 0%,
    #{rgba($Color-darkBlue, 0)} 40%,
    #{rgba($Color-darkBlue, 0)}60%,
    #{rgba($Color-darkBlue, 1)} 100%
  );
}

.light {
  --background-color: #{$Color-white};
  --text-color: #{$Color-darkBlue};
  --toggle-background-color: #{rgba($Color-darkGrey, 0.2)};
  --toggle-background-color-checked: #{$Color-orange};
  --border-color: #{$Color-grey-border};
  --loader-background-color: #{$Color-lightBlue};
  --loader-foreground-color: #{$Color-orange};
  --main-btn-background-hover-color: #{$Color-hover-grey};
  --background-color-btn-secondary: #{$Color-lightBlue};
  --secondary-btn-background-color: #{$Color-lightBlue};
  --secondary-btn-background-hover-color: #{$Color-hover-grey};
  --inputField-background-color: #{$Color-lightBlue};
  --inputField-border-color: #{$Color-lightGrey};
  --textLink-color: #{$Color-orange};
  --toast-background-color: #{$Color-white};
  --toast-color: #{$Color-black};
  --toast-icon-color-primary: #{$Color-orange};

  --klaasje-border-color: transparent;
  --klaasje-startup-background-color: #{$Color-orange};
  --task-background-color: #{$Color-lightBlue};
  --task-border: 1px solid transparent;
  --task-btn-background-color: #{$Color-btn-grey};
  --task-btn-text-color: #{$Color-white};
  --task-modal-gradient: linear-gradient(
    180deg,
    #{rgba($Color-white, 0)} 0%,
    #{rgba($Color-white, 0.908000700280112)} 25%,
    #{rgba($Color-white, 1)} 100%
  );
  --tag-modal-gradient: linear-gradient(
    180deg,
    #{rgba($Color-white, 1)} 0%,
    #{rgba($Color-white, 0.908000700280112)} 75%,
    #{rgba($Color-white, 0)} 100%
  );

  --meditation-toggle-background: #{$Color-lightBlue};
  --meditation-toggle-inactive-textcolor: #{$Color-btn-grey};
  --startup-waves-color: #{$Color-orange};
  --splash-gradient-background: linear-gradient(
    167deg,
    #{rgba($Color-white, 1)} 0%,
    #{rgba($Color-white, 0)} 40%,
    #{rgba($Color-white, 0)}60%,
    #{rgba($Color-white, 1)} 100%
  );
}

.dark,
.light {
  background: var(--background-color);
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#root {
  overflow-y: scroll !important;

  &.--no-scroll {
    overflow-y: hidden !important;
  }
}

.App {
  position: relative;
  background: var(--background-color);
  color: var(--text-color);
  min-height: 100%;
  width: 100%;
  max-width: $maxWidth; // Due to change
  margin: 0 auto;
  padding: calc(var(--headerCurrentHeight) + var(--vertical-padding))
    var(--horizontal-padding)
    calc(
      var(--mediaPlayerCurrentHeight) + var(--navbarCurrentHeight) +
        var(--vertical-padding)
    );
  --font-size: 16px;
  font-size: 16px;
  box-sizing: border-box;
  user-select: none;
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  flex-direction: column;

  & * {
    font-family: $mainFontFam;
  }

  text-align: center;

  .react-toggle-track {
    background: var(--toggle-background-color);
    box-shadow: inset 0 0 3px 0px rgb(0 0 0 / 20%);
  }

  .react-toggle-thumb {
    box-shadow: 0 0 4px 0px rgb(0 0 0 / 20%) !important;
    transform: scale(0.8);
    border: none !important;
  }

  .react-toggle--checked {
    .react-toggle-track {
      background: var(--toggle-background-color-checked);
    }
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: var(--toggle-background-color);
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background: var(--toggle-background-color-checked);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {

// }

// @media screen and (max-width: $breakpointMobileSmall) {

// }
