@import "./variables/colors";
@import "./variables/defaults";

.dark {
  --navTextColor: #{$Color-orange};
  --navItemFilter: grayscale(1) brightness(3);
}

.light {
  --navTextColor: #{$Color-orange};
  --navItemFilter: grayscale(1) brightness(1.8);
}

.NavigationBar {
  width: 100%;
  height: auto;
  max-width: $maxWidth;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  will-change: transform;
  
  background: var(--background-color);
  border-top: 1px solid var(--border-color);

  &__Content {
    padding: 10px var(--horizontal-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .NavigationItem {
      color: var(--navTextColor);
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-tap-highlight-color: transparent;
      width: 60px;

      &.active {
        pointer-events: none;
      }

      &:not(.active) {
        filter: var(--navItemFilter);
      }

      &__Icon {
        width: 30px;
        height: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &--myTasks {
          background-image: url("../../assets/icons/icon-nav-my-tasks.svg");
        }

        &--allTasks {
          background-image: url("../../assets/icons/icon-nav-all-tasks.svg");
        }

        &--meditation {
          background-image: url("../../assets/icons/icon-nav-meditation.svg");
        }

        &--profile {
          background-image: url("../../assets/icons/icon-nav-profile.svg");
        }
      }

      &__Text {
        margin-top: 5px;
        font-size: 10px;
        font-weight: 700;
        // text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: $breakpointMobileMedium) {
  .NavigationBar {
    &__Content {
      .NavigationItem {
        &__Icon {
          width: 28px;
          height: 28px;
        }

        &__Text {

        }
      }
    }
  }
}

@media screen and (max-width: $breakpointMobileSmall) {
  .NavigationBar {
    &__Content {
      padding: 5px var(--horizontal-padding);
      .NavigationItem {
        &__Icon {
          width: 25px;
          height: 25px;
        }

        &__Text {
          font-size: 0.7em;
        }
      }
    }
  }
}
