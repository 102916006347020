@import "./variables/defaults";

.actionModal {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  background: var(--background-color);
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: none;
  pointer-events: visible;
  transition: 0.5s box-shadow ease-in-out;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  will-change: transform;

  &--visible {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
  }

  &__container {
    position: fixed;
    pointer-events: none;
    width: 100%;
    max-height: calc(
      100% - var(--headerCurrentHeight) - var(--navbarCurrentHeight)
    );
    max-width: 460px;
    z-index: 999;
    bottom: var(--navbarCurrentHeight);
    left: 0px;
    display: flex;
    flex-direction: column;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: $maxWidth;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }

  &__closeButton {
    position: absolute;
    right: var(--horizontal-padding);
    top: 20px;
    // width: 20px;
    // height: 20px;
    z-index: 1;
    color: #fff;
    font-size: 25px;
  }

  &__header {
    height: 200px;
    position: relative;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;

    &--food {
      background: #99d3c5;
    }

    &--bedroom {
      background: #ffdbaa;
    }

    &--mental {
      background: #d2afce;
    }

    &--sleep {
      background: #bacef4;
    }

    &__copyContainer {
      position: absolute;
      max-width: 215px;
      width: 50%;
      font-weight: 700;
      font-size: 1.5em;
      line-height: 1.1em;
      text-align: left;
      top: 16px;
      left: var(--horizontal-padding);
      color: #fff;

      &__tagContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6px;
        margin-bottom: 10px;

        &__tag {
          background: white;
          font-size: 0.55em;
          padding: 2px 10px;
          border-radius: 999px;
          font-weight: 700;
          flex-grow: 0;
          flex-shrink: 0;

          &--food {
            color: #00916e;
          }

          &--bedroom {
            color: #ffa62b;
          }

          &--mental {
            color: #8f3985;
          }

          &--sleep {
            color: #5386e4;
          }
        }
      }
    }

    .actionKlaasje {
      &.--food {
        right: 0;
        bottom: -1px;
      }

      &.--mental {
        right: 0;
        bottom: -15px;
      }

      &.--bedroom {
        right: -20px;
        bottom: -28px;
      }

      &.--sleep {
        right: 20px;
        bottom: 0;
        max-height: 140px;
      }
    }
  }

  &__mainContent {
    text-align: left;
    overflow-y: scroll;
  }

  &__taskDescription {
    padding: 60px var(--horizontal-padding) 100px;

    &__tagContainer {
      position: absolute;
      display: flex;
      flex-direction: row;
      gap: 6px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      background: var(--tag-modal-gradient);
      padding: var(--vertical-padding) var(--horizontal-padding);
      width: 100%;

      &__tag {
        background: white;
        font-size: 0.75em;
        padding: 2px 10px;
        font-weight: 700;
        border-radius: 999px;
        flex-grow: 0;
        flex-shrink: 0;

        &--food {
          background: #99d3c5;
          color: #00916e;
        }

        &--bedroom {
          background: #ffdbaa;
          color: #ffa62b;
        }

        &--mental {
          background: #d2afce;
          color: #8f3985;
        }

        &--sleep {
          background: #bacef4;
          color: #5386e4;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    display: flex;
    bottom: 0;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    background: var(--task-modal-gradient);
    padding: var(--vertical-padding) var(--horizontal-padding);
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {

// }

@media screen and (max-width: $breakpointMobileSmall) {
  .actionModal {
    &__closeButton {
      top: 15px;
    }

    &__header {
      height: 115px;

      &__copyContainer {
        width: 60%;
      }

      .actionKlaasje {
        height: 80px;

        &.--food {
          right: 20px;
          bottom: 0px;
        }

        &.--mental {
          right: 8px;
          bottom: -10px;
        }

        &.--bedroom {
          right: -13px;
          bottom: -12px;
        }

        &.--sleep {
          right: 20px;
          bottom: 0;
        }
      }
    }

    &__taskDescription {
      padding-top: calc(var(--vertical-padding) * 2);
      padding-bottom: 75px;
    }
  }
}
