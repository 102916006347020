@import "./variables/colors";
@import "./variables/defaults";

.light {
  --input-background: var(--background-color);
  --input-save-button-background-disabled: #{$Color-lightBlue};
  --input-save-button-background: #{$Color-lightBlue};
  --input-save-button-border: #{$Color-orange};
  --input-save-button-color-disabled: #{$Color-grey};
  --input-save-button-color: #{$Color-orange};
}

.dark {
  --input-background: #{$Color-darkerBlue};
  --input-save-button-background-disabled: #{$Color-darkBlue};
  --input-save-button-background: #{$Color-darkBlue};
  --input-save-button-border: #{$Color-orange};
  --input-save-button-color-disabled: #{transparentize($Color-white, 0.7)};
  --input-save-button-color: #{$Color-white};
}

.InputField {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: var(--vertical-padding) var(--horizontal-padding);
  box-sizing: border-box;
  background: var(--input-background);
  box-shadow: 0 0 25px rgba(0,0,0,0.2);
  user-select: none;
  display: flex;
  flex-direction: column;

  &.--time {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .InputField__input {
      margin-top: 0;
      align-self: center;
    }
  }

  &__container {
    &__time {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__input {
    padding: 12px 20px;
    margin-bottom: 10px;
    
    &__button {
      background: $Color-orange;
      color: $Color-white;
      font-weight: 700;
      border-radius: 1000px;

      &.disabled {
        background: transparent;
      }

      &.save {
        margin-top: 10px;
        background: var(--input-save-button-background);
        color: var(--input-save-button-color);
        align-self: flex-end;
        border: 1px solid var(--input-save-button-border);

        span {
          position: relative;
          display: inline-block;
          margin-right: 0.5em;
          top: -0.03em;
        }

        &.disabled {
          background: transparent;
          background: var(--input-save-button-background-disabled);
          color: var(--input-save-button-color-disabled);
          border: 1px solid transparent;
        }
      }

      &.inline {
        background: transparent;
        color: $Color-orange;
        font-weight: 700;
        border-radius: 1000px;
        margin-top: 10px;

        &.disabled {
          background: transparent;
          color: $Color-lightGrey;
        }
      }
    }

    &__select {
      padding: 11px 20px;
      border: 1px solid $Color-lightGrey;
      color: $Color-orange;
      font-weight: 700;
      border-radius: 1000px;
      box-sizing: border-box;


      &.selected {
        padding: 12px 20px;
        border: 0;
        background: $Color-orange;
        color: $Color-white;
      }
    }

    &__time {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0px;
      height: 0px;
      padding: 0;
      margin: 0;
      overflow: hidden;
      border: none;

      &__label {
        color: $Color-orange;
        font-size: 3em;
        font-weight: 700;
        line-height: 1em;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .InputField {
    &__input {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}
