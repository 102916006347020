@import "./variables/defaults";
@import "./variables/colors";

.light {
  --profile-category-border-color: #{$Color-lightGrey};
}

.dark {
  --profile-category-border-color: #{$Color-lightBlue};
}

.TaskOverview {
  &__content {
    .contentBlock {
      &:not(:first-child),
      &:not(:last-of-type) {
        margin-top: 30px;
        border-top: 1px solid var(--profile-category-border-color);
        padding-top: 20px;
      }
    }
  }
}

.home {
  &__text {
    .Klaasje {
      margin: 0 auto;
      max-height: 150px;

    }
  }
}
