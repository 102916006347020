@import "colors";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

$test: $Color-darkBlue;
$mainFontFam: "Open Sans", sans-serif;
$googleFontFam: "Roboto", sans-serif;

// Default Vars
$maxWidth: 460px;
$menuBarPadding: 65px;
$horizontalPadding: 30px;
$verticalPadding: 20px;

html {
  --horizontal-padding: #{$horizontalPadding};
  --vertical-padding: #{$verticalPadding};
}

// Medium Breakpoint Specifics
$breakpointMobileMedium: 380px;
@media screen and (max-width: $breakpointMobileMedium) {
  html {
    --horizontal-padding: calc(#{$horizontalPadding} / 3 * 2);
    --vertical-padding: calc(#{$verticalPadding} / 2);
  }

  .App {
    --font-size: 14px;
    font-size: 14px;
  }
}

// Small Breakpoint Specifics
$breakpointMobileSmall: 340px;
@media screen and (max-width: $breakpointMobileSmall) {
  html {
    --horizontal-padding: calc(#{$horizontalPadding} / 2);
    --vertical-padding: calc(#{$verticalPadding} / 2);
  }

  .App {
    --font-size: 13px;
    font-size: 13px;
  }
}
