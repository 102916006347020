@import "./variables/colors";
@import "./variables/defaults";

.Tos,
.NotificationMod {
  position: fixed;
  z-index: 1000;
  top: var(--headerCurrentHeight);
  bottom: var(--navbarCurrentHeight);
  left: 0;
  right: 0;
  background: var(--background-color);

  display: flex;
  flex-direction: column;

  &__content {
    padding: var(--vertical-padding) var(--horizontal-padding) 100px;
    text-align: left;
    font-size: 13px;
    flex-grow: 0;
    flex-shrink: 1;
    overflow-y: scroll;

    h2 {
      font-size: 2em;
      margin-top: var(--vertical-padding);
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.5em;
      margin-top: calc(var(--vertical-padding) * 2);
      margin-bottom: 0;
    }

    p {
      &.Tos__content__subline--highlight {
        color: $Color-orange;
        margin: 0;
        font-weight: 700;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(var(--vertical-padding) * 2) var(--horizontal-padding)
      var(--vertical-padding);
    display: flex;
    justify-content: space-around;
    background: var(--task-modal-gradient);
  }

  .cursive {
    font-style: oblique;
  }

  .underline {
    text-decoration: underline;
  }

  h5 {
    text-decoration: underline;
    margin-bottom: 0px;
    font-size: 1em;
    font-weight: 400;
  }

  .spaced li {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background: #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
Label the data
You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
*/
  td:nth-of-type(1):before {
    content: "Naam";
  }
  td:nth-of-type(2):before {
    content: "Aanbieder";
  }
  td:nth-of-type(3):before {
    content: "Doel";
  }
  td:nth-of-type(4):before {
    content: "Vervaltermijn";
  }
  td:nth-of-type(5):before {
    content: "Type";
  }
}
