@import "./variables/defaults";
@import "./variables/colors";

$playerPadding: 10px;
$playerRadius: 0px;

.light {
  --player-background-color: #fff;
  --player-top-border-color: #242424;
  --player-title-color: var(--text-color);
  --player-time-color: #{$Color-grey};
  --player-ripple-background-color: #{rgba($Color-orange, 0.7)};
  --player-progress-color: #{$Color-orange};
  --player-progress-back-color: #{rgba($Color-orange, 0.2)};
}

.dark {
  --player-background-color: #{$Color-darkerBlue};
  --player-top-border-color: #242424;
  --player-title-color: var(--text-color);
  --player-time-color: #{rgba(var(--text-color), 0.5)};
  --player-ripple-background-color: #{rgba(#fff, 0.7)};
  --player-progress-color: #{$Color-orange};
  --player-progress-back-color: #{rgba($Color-orange, 0.2)};
}

.MediaPlayer {
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: var(--navbarCurrentHeight);
  width: 100%;
  max-width: $maxWidth;
  background: var(--player-background-color);
  box-sizing: border-box;
  box-shadow: 0 0 20px -5px rgba(0,0,0,0.3);
  border-top-left-radius: $playerRadius;
  border-top-right-radius: $playerRadius;

  * {
    box-sizing: border-box;
  }

  &__progress {
    pointer-events: visible;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 2px;
    background-color: var(--player-progress-back-color);

    &__current {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--player-progress-color);

      &__handle {
        position: absolute;
        top: 50%;
        right: 0;
        width: 20px;
        height: 20px;
        transform: translate(50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        &__dot {
          width: 10px;
          height: 10px;
          background-color: var(--player-progress-color);
          border-radius: 50%;
        }
      }
    }
  }

  &__content {
    position: relative;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: auto;
    padding: $playerPadding var(--horizontal-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__loading {
      position: absolute;
      background: var(--player-background-color);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;

      .Loading__spinner {
        font-size: 4px;
      }
    }


    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      flex-grow: 0;
      flex-shrink: 1;
      max-width: 65%;
      
      &__title {
        font-size: 1.1em;
        font-weight: 700;
        margin: 0;
        color: var(--player-title-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        // margin-bottom: 5px;
      }

      &__time {
        font-size: 0.8em;
        font-style: italic;
        margin: 0;
        color: var(--player-time-color);

        &.--timeLeft {
          font-size: 0.6875em;
          font-weight: 700;
          font-style: normal;
          color: var(--background-color);
          background: var(--text-color);
          padding: 1px 4px;
          border-radius: 3px;
          margin-top: 1px;
        }
      }
    }

    &__controls {
      pointer-events: visible;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.25em;

      &.--no-jumping {
        .player__control {
          &--reverse, &--forwards {
            display: none;
          }

          &--play {
            margin-right: 0;
          }
        }
      }

      .player__control__container {
        position: relative;
        display: flex;
        margin: 0 0.4em;

        &:last-child {
          // margin-left: 0.3em;
          margin-right: 0;
        }

        .clickRipple {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 4em;
          height: 4em;
          background: var(--player-ripple-background-color);
          border-radius: 50%;
        }
      }

      .player__control {
        &--play, &--timer {
          font-size: 1.4em;
        }
      }
      
    }
  }
}
