@import "./variables/defaults";
@import "./variables/colors";

.startup {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__klaasje {
    position: relative;
    width: 90%;
    left: 50%;
    transform: translateX(-50%)
  }

  &__copyContainer {
    position: relative;
    margin-top: 20px;

    &__header {
      font-size: 20px;

      &--bold {
        font-weight: 800;
      }
    }

    &__cta {
      position: relative;
      margin-top: 20px;

      background: $Color-orange;
      color: $Color-white;
      display: inline-block;
      padding: 10px 40px;
      border-radius: 999px;
    }
  }

  &__waves {
    position: fixed;
    width: 100%;
    height: 100%;

    .line {
      fill: var(--startup-waves-color);
    }

    svg {
      position: absolute;
      bottom: -230px;
      width: 180%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
