.actionKlaasje {
  position: absolute;
  width: 200px;
  height: 180px;
  margin: auto;

  &__imageContainer {
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      bottom: 0px;
      object-fit: contain;
      object-position: bottom right;
    }
  }
}
