@import "./variables/colors";
@import "./variables/defaults";

$chatBubbleBackgroundColorLight: #F3F4F6;
$chatBubbleAnswerBackgroundColorLight: $Color-orange;
$chatBubbleTextColorLight: #003148;
$chatBubblePadding: 15px 20px;
$chatBubbleRadius: 15px;
$chatBubbleMinSpacing: 10px;
$chatBubbleGroupSpacing: calc($chatBubbleMinSpacing * 2);

.dark {
  // --background-color: #{$Color-darkBlue};
  --chatbubble-color: #{darken($color: $Color-darkBlue, $amount: 5%)};
}

.light {
  // --background-color: #{$Color-white};
  --chatbubble-color: #{$chatBubbleBackgroundColorLight};
}


.Intake {

  &__chatHeader {
    position: fixed;
    top: var(--headerCurrentHeight); // Menu bar height
    width: calc(100% - var(--horizontal-padding) * 2);
    height: auto;
    padding: 10px 20px 15px;
    background: var(--chatbubble-color);
    text-align: left;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;

    &__klaasje {
      width: 70px;
      height: 80px;
      background: url('../../assets/images/klaasje-phone-01.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
    }

    &__textContainer {
      margin-left: 20px;

      &__title {
        margin: 0;
        font-size: 1.2em;
        font-weight: 700;
      }

      &__subtitle {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: 300;
      }
    }

    &__progressBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background: rgba($Color-orange, 0.2);
      overflow: hidden;

      &__progress {
        width: 20%;
        height: 100%;
        background: $Color-orange;
      }
    }
  }

  .conversationFlow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 100px;
    padding-bottom: var(--intakeInputCurrentHeight);

    .bubbleGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $chatBubbleGroupSpacing;

      &:last-child {
        margin-bottom: 0;
      }

      &.bubbleGroup--answer {
        align-items: flex-end;
        .chatBubble {
          color: $Color-white;
          background: $chatBubbleAnswerBackgroundColorLight;
          border-radius: $chatBubbleRadius 0 0 $chatBubbleRadius;

          &:last-child {
            border-radius: $chatBubbleRadius 0 $chatBubbleRadius $chatBubbleRadius;
          }
  
          &:first-child {
            border-radius: $chatBubbleRadius $chatBubbleRadius 0 $chatBubbleRadius;
          }
        }
      }

      .chatBubble {
        background: var(--chatbubble-color);
        color: var(--text-color);
        text-align: left;
        box-sizing: border-box;
        max-width: 90%;
        border-radius: 0 $chatBubbleRadius $chatBubbleRadius 0;
        margin-bottom: $chatBubbleMinSpacing;

        &:last-child {
          border-radius: 0 $chatBubbleRadius $chatBubbleRadius $chatBubbleRadius;
        }

        &:first-child {
          border-radius: $chatBubbleRadius $chatBubbleRadius $chatBubbleRadius 0;
        }
  
        .text {
          display: block;
          padding: $chatBubblePadding;
          overflow-wrap: break-word;
        }
      }
    }

    
  }
}

@media screen and (max-width: $breakpointMobileMedium) {
  .Intake {
    &__chatHeader {
      &__klaasje {
        width: 60px;
        height: 70px;
      }
    }
  }
}

@media screen and (max-width: $breakpointMobileSmall) {
  .Intake {
    &__chatHeader {
      &__klaasje {
        width: 50px;
        height: 60px;
      }
    }
  }
}
