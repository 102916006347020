@import "./variables/colors";
@import "./variables/defaults";

.Offline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .actionKlaasje {
    position: relative;
    z-index: 1;
    margin: 0;
    height: 250px;
  }
  
  

  &__content {
    position: relative;
    z-index: 2;

    h2 {
      font-size: 25px;
      font-weight: 700;
      margin: 25px 0 10px;
    }

    p {
      margin: 0 auto 20px;
    }
  }

  &__logo {
    &__container {
      position: absolute;
      z-index: 2;
      bottom: 5vh;
      width: 150px;

      .logo-subline {
        width: 100%;

        #logoSublineSvg {
          width: 100%;
        }
      }
    }

    
  }

  &__background__decal {
    width: 170vw;
    height: 170vw;
    position: absolute;
    background: var(--border-color);
    border-radius: 9999px;
    z-index: 0;
    opacity: 0.2;
  }
}
