@import "./variables/defaults";

.Header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: $maxWidth; // Due to change
  height: auto;
  margin: 0 auto;
  padding: 20px var(--horizontal-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
  box-sizing: border-box;

  &.underlined {
    border-bottom: 1px solid var(--border-color);
  }

  &__logo {
    display: flex;
    height: 20px;
    width: auto;

    svg {
      height: 100% !important;
    }
  }

  &__toggle {
    margin-left: auto;
    order: 2;
    display: flex; // Fixes height problem
  }

  .developmentFlag {
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    background-color: #cc0000;
    padding: 2px 5px;
    border-radius: 5px;
  }
}

@media screen and (max-width: $breakpointMobileMedium) {
  .Header {
    padding-top: 18px;
    padding-bottom: 18px;

    &__logo {
      height: 18px;
    }
  }
}

@media screen and (max-width: $breakpointMobileSmall) {
  .Header {
    padding-top: 16px;
    padding-bottom: 16px;
    
    &__logo {
      height: 17px;
    }
  }
}
