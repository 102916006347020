@import "./variables/colors";
@import "./variables/defaults";

.light {
  --button-outline-border-color: #{$Color-lightGrey};
}

.dark {
  --button-outline-border-color: #{$Color-white};
}

.HomeModal {
  position: fixed;
  z-index: 9999;
  border-radius: 1000px;
  font-size: 13px;
  background: var(--toast-background-color);
  color: var(--toast-color);
  left: 50%;
  transform: translateX(-50%);
  margin: 10px auto;
  padding: 20px;
  bottom: var(--navbarCurrentHeight);
  filter: drop-shadow(0 0px 1px #707070);
  &:after {
    transform: rotate(45deg) translate(-50%);
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: var(--toast-background-color);
    transform: translateX(-50%) translateY(-10px) rotate(45deg);
    transform-origin: center;
    left: 50%;
    position: absolute;
    z-index: -1;
  }
  &__text {
    &__title {
      font-size: 14px;
      font-weight: bold;
    }
    &__nobreak {
      word-break: keep-all;
      white-space: nowrap;
    }
  }
  &__shareIcon {
    width: 30px;
    display: inline-block;
    position: relative;
    margin-top: -11px;
    top: 10px;
  }
}
