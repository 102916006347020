@import "./variables/colors";
@import "./variables/defaults";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-color);

  &__logo {
    width: 100%;
    height: auto;
  }
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-color);

  &--small {
    background: rgba(0, 0, 0, 0.3);

    .Loading__content {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
      min-width: 145px;
      min-height: 145px;
      width: auto;
      height: auto;
      border-radius: 10px;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--background-color);

    &__message {
      font-size: 13px;
      font-weight: 700;
      color: inherit;
    }

    .Loading__logo {
      max-width: 50%;
      .logo-subline {
        max-width: 200px;

        #logoSublineSvg {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  &__background {
    position: absolute;
    width: 150%;
    height: 550px;
    opacity: 0.2;

    &__gradient {
      width: 100%;
      height: 100%;
      position: absolute;
      background: var(--splash-gradient-background);
    }

    &--top {
      position: absolute;
      // width: 150%;
      left: -25%;
      top: -40%;
    }

    &--bottom {
      position: absolute;
      // width: 150%;
      right: -15%;
      bottom: -40%;
    }

    .line {
      fill: $Color-orange;
    }
  }
}

.Loading__spinner,
.Loading__spinner:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  flex-grow: 0;
  flex-shrink: 0;
}

.Loading__spinner {
  margin: 15px;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--loader-background-color);
  border-right: 1.1em solid var(--loader-background-color);
  border-bottom: 1.1em solid var(--loader-background-color);
  border-left: 1.1em solid var(--loader-foreground-color);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .Loading {
    &__content {
      .Loading__logo {
        max-width: 40%;
      }
    }
    

    &__background {
      &--top {
        left: -20%;
        top: -40%;
      }

      &--bottom {
        right: -50%;
        bottom: -50%;
      }
    }

    &__spinner {
      width: 5em;
      height: 5em;
      border-width: 0.7em;
    }
  }
}
