@import "./variables/colors";

.toggle {

  background: var(--meditation-toggle-background);
  color: darkblue;
  border-radius: 8px;
  font-weight: 800;

  &__options {
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
    padding: 3px;

    &__selector {
      flex-grow: 1;
      padding: 5px 3px;
      border-radius: 4px;

      &--active {
        background: $Color-orange;
        color: white;
      }

      &--inactive {
        background: var(--meditation-toggle-background);
        color: var(--meditation-toggle-inactive-textcolor)
      }
    }
  }
}
