@import "./variables/defaults";
@import "./variables/colors";

.light {
  --profile-category-border-color: #{$Color-lightGrey};
  --contentBlock-special-background-color: #{$Color-lightBlue};
}

.dark {
  --profile-category-border-color: #{$Color-lightBlue};
  --contentBlock-special-background-color: #{$Color-darkerBlue};
}

.Profile {
  &__content {
    .contentBlock {
      &:not(:first-child),
      &:not(:last-of-type) {
        margin-top: 30px;
        border-top: 1px solid var(--profile-category-border-color);
        padding-top: 20px;
      }
    }
  }
}

.contentBlock {
  text-align: left;
  margin: 20px auto;

  &.--special {
    padding: var(--vertical-padding) var(--horizontal-padding);
    background-color: var(--contentBlock-special-background-color);
    border-left: 3px solid $Color-orange;
  }

  &.--noMargin {
    margin: 0;
  }

  &__title {
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 3px;
  }

  &__tagContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;

    &__tag {
      background: white;
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 999px;
      font-weight: 700;

      &--food {
        color: #00916e;
      }

      &--bedroom {
        color: #ffa62b;
      }

      &--mental {
        color: #8f3985;
      }

      &--sleep {
        color: #5386e4;
      }
    }
  }

  &__copy {
    font-size: 0.875em;
    font-weight: normal;
  }
}

@media screen and (max-width: $breakpointMobileMedium) {
}

@media screen and (max-width: $breakpointMobileSmall) {
  .contentBlock {
    &__copy {
      font-size: 0.95em;
    }
  }
}
