@import "./variables/colors";
@import "./variables/defaults";
 
.Form {
  &__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__link {
    color: var(--textLink-color);
    font-weight: bold;
    padding: 10px;
  }

  &__textBox {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    box-sizing: border-box;
    border: 1px solid var(--inputField-border-color);
    box-shadow: inset 0 0 3px $Color-inset-border;
    background: var(--inputField-background-color) !important;
    color: var(--text-color);
    font-size: 0.9em;
    outline-width: 0;
  }
  &__btn {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    border: none;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    background: $Color-orange;
    color: $Color-white;
    font-weight: bold;
    font-size: 0.9em;

    &:hover {
      background: var(--main-btn-background-hover-color);
    }

    &--secondary {
      background: var(--secondary-btn-background-color);
      color: $Color-darkBlue;

      &:hover {
        background: var(--secondary-btn-background-hover-color);
      }
    }

    &.Form__google {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $Color-white;
      border: 1px solid $Color-lightGrey;
      font-weight: 500;
      color: $Color-grey;

      * {
        font-family: $googleFontFam !important;
      }

      .Google__logo {
        background: url("../../assets/images/google-logo.svg");
        width: 22px;
        height: 22px;
        margin-right: 18px;
      }
    }
  }

  &__errorField {
    color: $Color-red;
    // background: #ffe0e0;
    // border: 1px solid #a33a3a;
    // padding: 5px;
    margin: 15px 0px;
    // border-radius: 8px;
  }

  &__label {
    font-weight: 300;
    margin: 25px 0 5px;
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .Form {
    .Klaasje {
      margin-top: 0;
      height: 130px;
    }

    &__btn {
      &.Form__google {
        .Google__logo {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }
}
