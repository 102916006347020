@import "./variables/defaults";

.ErrorView {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .actionKlaasje {
    position: relative;
    z-index: 1;
    margin: 0;
    height: 250px;
  }
  
  

  &__content {
    h2 {
      font-size: 3.75em;
      font-weight: 700;
      margin: 0;
    }

    p {
      margin: 0 auto 20px;
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .ErrorView {
    .actionKlaasje {
      height: 150px;
    }
  }
}
