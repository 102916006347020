@import "./variables/colors";
@import "./variables/defaults";

.Register {
  a {
    color: $Color-orange;
    text-decoration: none;
  }

  &__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__inputGroup {
    max-width: 460px;
    width: 100%;
    position: relative;
    display: block;
  }

  &__textBox {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    border: none;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    box-sizing: border-box;
    // border: 2px solid rgba(#000000, 0.16);
    box-shadow: inset 0 0 3px $Color-inset-border;
    background: $Color-lightBlue !important;
    font-size: 0.9em;

    &--dark {
      border: 2px solid #ffffff;
      background: $Color-darkBlue !important;
    }
  }

  &__toggleBtn {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    background-color: red;

    > * {
      position: absolute;
      height: 100%;
      top: 0px;
      right: 0px;
      padding: 0px 10px;
      font-weight: bold;
      font-size: 16px;
      color: $Color-darkGrey;
      background-color: $Color-lightBlue;
      border: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 1px solid $Color-lightGrey;
      -webkit-text-stroke: 3px;
    }
  }

  &__btn {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    border: none;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    background: $Color-orange;
    color: $Color-white;
    font-weight: bold;
    font-size: 0.9em;

    &--secondary {
      background: $Color-lightBlue;
      color: $Color-darkBlue;
    }

    &.Form__google {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $Color-white;
      border: 1px solid $Color-lightGrey;
      font-weight: 500;
      color: $Color-grey;

      * {
        font-family: $googleFontFam !important;
      }

      .Google__logo {
        background: url("../../assets/images/google-logo.svg");
        width: 22px;
        height: 22px;
        margin-right: 18px;
      }
    }
  }

  &__label {
    font-weight: 300;
    margin: 25px 0 5px;
  }
}

@media screen and (max-width: $breakpointMobileSmall) {
  .Register {
    &__btn {
      &.Form__google {
        .Google__logo {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }
}
