@import "./variables/colors";
@import "./variables/defaults";

.light { 
  --timePicker-overlay-gradient: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 90%);
}

.dark {
  --timePicker-overlay-gradient: linear-gradient(0deg, rgba(0,32,47,1) 10%, rgba(0,32,47,0) 40%, rgba(0,32,47,0) 60%, rgba(0,32,47,1) 90%);
}

.TimePicker {
  width: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-top: 1px solid var(--border-color);
  will-change: height;

  &__overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: var(--timePicker-overlay-gradient);
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: var(--vertical-padding) var(--horizontal-padding);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__column {
      font-size: 2.1em;
      line-height: 1.2em;
      height: 1.2em;
      display: flex;
      justify-content: center;
    }

    &__scrollList {
      display: flex;
      flex-direction: column;
      height: fit-content;
    }

    &__scrollItem {
      font-size: 1em;
      font-weight: 700;
      text-align: right;
    }
  }
}

// @media screen and (max-width: $breakpointMobileMedium) {
  
// }

@media screen and (max-width: $breakpointMobileSmall) {
  .TimePicker {
    height: 100px;
  }
}
