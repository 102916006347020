@import "./variables/defaults";
@import "./variables/colors";

$chatBubbleBackgroundColorLight: #f3f4f6;
$chatBubbleAnswerBackgroundColorLight: $Color-orange;
$chatBubbleTextColorLight: #003148;
$chatBubblePadding: 20px 20px;
$chatBubbleRadius: 15px;
$chatBubbleMinSpacing: 10px;
$chatBubbleGroupSpacing: calc($chatBubbleMinSpacing * 2);

.Mobile.--landscape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .Mobile__message {
    width: 80%;
    background: $Color-lightBlue;
    color: $Color-darkerBlue;
    padding: 50px;
    border-radius: 0 25px 25px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.3));
    box-sizing: border-box;

    &__container {
      text-align: left;
      font-size: 16px;
      font-family: $mainFontFam;
    }

    &__title {
      font-size: 1.5em;
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    svg {
      font-size: 70px;
      margin: 0 0 0 35px;
    }
  }
}

.Desktop {
  --chatbubble-color: #{$chatBubbleBackgroundColorLight};
  width: 100vw;
  background: $Color-lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  &__container {
    max-width: 1770px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 50px;
  }

  &__row {
    display: flex;
    gap: 100px;
    width: 100%;
    //justify-content: center;
    //align-items: center;
    align-content: stretch;

    &--top {
      min-height: 100vh;
      align-items: center;
    }

    &--bottom {
      & .Desktop__row__collumn {
        padding: 0px;
        align-items: center;
        text-align: center;
        width: 100%;
      }
    }

    &__collumn {
      padding: 50px;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;

      &--white {
        border-radius: 30px;
        background: white;
      }
    }
  }

  &__conversation {
    color: $Color-darkBlue;
    &__header {
      background-color: $Color-lightBlue;
      border-radius: 15px;
      position: relative;

      & .Klaasje {
        width: 200px;
        max-width: 30%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: block;

        @media (max-width: 1200px) {
          display: none;
        }
      }
    }

    &__headerText {
      text-align: left;
      width: 50%;
      padding: 50px;
      margin-left: 30%;

      @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0;
        box-sizing: border-box;
        padding: 30px;
      }
    }

    &__title {
      margin-bottom: 10px;
      margin-top: 0px;
      padding: 0px;
    }

    &__text {
      margin: 0px;

      &--light {
        opacity: 60%;
      }
    }

    .conversationFlow {
      margin-top: 20px;

      .bubbleGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: $chatBubbleGroupSpacing;

        &:last-child {
          margin-bottom: 0;
        }

        &.bubbleGroup--answer {
          align-items: flex-end;
          .chatBubble {
            color: $Color-white;
            background: $chatBubbleAnswerBackgroundColorLight;
            border-radius: $chatBubbleRadius 0 0 $chatBubbleRadius;

            &:last-child {
              border-radius: $chatBubbleRadius 0 $chatBubbleRadius
                $chatBubbleRadius;
            }

            &:first-child {
              border-radius: $chatBubbleRadius $chatBubbleRadius 0
                $chatBubbleRadius;
            }
          }
        }

        .chatBubble {
          background: var(--chatbubble-color);
          color: var(--text-color);
          text-align: left;
          box-sizing: border-box;
          max-width: 90%;
          border-radius: 0 $chatBubbleRadius $chatBubbleRadius 0;
          margin-bottom: $chatBubbleMinSpacing;

          &:last-child {
            border-radius: 0 $chatBubbleRadius $chatBubbleRadius
              $chatBubbleRadius;
          }

          &:first-child {
            border-radius: $chatBubbleRadius $chatBubbleRadius $chatBubbleRadius
              0;
          }

          .text {
            display: block;
            padding: $chatBubblePadding;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }

  &__title {
    color: $Color-darkBlue;
    font-size: 32px;
    margin-top: 70px;
  }

  &__text {
    color: $Color-darkBlue;
    font-size: 24px;

    &--light {
      opacity: 60%;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__qrHolder {
    border-radius: 20px;
    border: 3px solid $Color-orange;
    width: auto;
    background-color: white;
    padding: 30px;
    display: inline-flex;
    margin-bottom: 50px;

    &__qr {
      width: 150px;
      height: 150px;
    }
  }
  &__carret {
    padding: 20px;
  }

  & #LogoSvg {
    width: 220px;
    height: auto;
  }
  &__questions {
    &__title {
      color: $Color-darkBlue;
      font-size: 32px;
      text-align: left;
      margin: 50px 0px;
    }

    &__item {
      text-align: left;
      margin-bottom: 50px;
      height: auto;
      overflow: auto;

      &__icon {
        background: white;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        display: inline-block;

        & svg {
          left: 50%;
          top: 50%;
          position: relative;
          transform: translate(-50%, -50%);
        }
      }

      &__text {
        color: #003148;
        max-width: calc(100% - 80px);
        margin-left: 20px;
        float: right;
      }

      &__title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 10px;
      }

      &__answer {
        font-weight: 200;
        font-size: 24px;
      }
    }
  }

  &__footer {
    padding: 50px;
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100%;

    svg {
      float: left;
      width: auto !important;
      height: 30px !important;
    }

    &__text {
      color: $Color-darkBlue;
      float: right;
    }
    &__link {
      color: $Color-darkBlue;
    }
  }
}
