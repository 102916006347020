.Klaasje {
  width: 80%;
  margin: 20px auto;
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }

  #Path_11 {
    stroke: var(--klaasje-border-color);
    stroke-width: 5;
  }
}
