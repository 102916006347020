@import "./variables/colors";
@import "./variables/defaults";

.App .Reset {
  &__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__textBox {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    border: none;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    box-sizing: border-box;
    // border: 2px solid rgba(#000000, 0.16);
    box-shadow: inset 0 0 3px $Color-inset-border;
    background: $Color-lightBlue !important;
    font-size: 0.9em;

    &--dark {
      border: 2px solid #ffffff;
      background: $Color-darkBlue !important;
    }
  }
  &__btn {
    max-width: 460px;
    width: 100%;
    padding: 0.75em 0.9em;
    border-radius: 10px;
    border: none;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-bottom: 12px;
    background: $Color-orange;
    color: $Color-white;
    font-weight: bold;
    font-size: 0.9em;

    &--secondary {
      background: $Color-lightBlue;
      color: $Color-darkBlue;
    }

    &.Form__google {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $Color-white;
      border: 1px solid $Color-lightGrey;
      font-weight: 500;
      color: $Color-grey;

      * {
        font-family: $googleFontFam !important;
      }

      .Google__logo {
        background: url("../../assets/images/google-logo.svg");
        width: 22px;
        height: 22px;
        margin-right: 18px;
      }
    }
  }

  &__label {
    font-weight: 300;
    margin: 25px 0 5px;
  }
}
